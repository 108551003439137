/* General styles for the modal */

/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.md-perspective,
.md-perspective body {
	height: 100%;
	overflow: hidden;
}

.md-perspective body  {
	background: #222;
	-webkit-perspective: 600px;
	perspective: 600px;
}

.container {
	background: #e74c3c;
	min-height: 100%;
}

.md-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50%;
	max-width: 630px;
	min-width: 320px;
	height: auto;
	z-index: 2000;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.md-show {
	visibility: visible;
}

.md-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 0;
	background: rgba(143,27,15,0.8);
	transition: all 0.3s;
}

.md-show ~ .md-overlay {
	opacity: 1;
	visibility: visible;
}

/* Content styles */
.md-content {
	color: #fff;
	background: #e74c3c;
	position: relative;
	border-radius: 3px;
	margin: 0 auto;
}

.md-content h3 {
	margin: 0;
	padding: 0.4em;
	text-align: center;
	font-size: 2.4em;
	font-weight: 300;
	opacity: 0.8;
	background: rgba(0,0,0,0.1);
	border-radius: 3px 3px 0 0;
}

.md-content > div {
	padding: 15px 40px 30px;
	margin: 0;
	font-weight: 300;
	font-size: 1.15em;
}

.md-content > div p {
	margin: 0;
	padding: 10px 0;
}

.md-content > div ul {
	margin: 0;
	padding: 0 0 30px 20px;
}

.md-content > div ul li {
	padding: 5px 0;
}

.md-content button {
	display: block;
	margin: 0 auto;
	font-size: 0.8em;
}

/* Individual modal styles with animations/transitions */

/* Effect 1: Fade in and scale up */
.md-effect-1 .md-content {
	-webkit-transform: scale(0.7);
	transform: scale(0.7);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-1 .md-content {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Effect 2: Slide from the right */
.md-effect-2 .md-content {
	-webkit-transform: translateX(20%);
	transform: translateX(20%);
	opacity: 0;
	transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.md-show.md-effect-2 .md-content {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
}

/* Effect 3: Slide from the bottom */
.md-effect-3 .md-content {
	-webkit-transform: translateY(20%);
	transform: translateY(20%);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-3 .md-content {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

/* Effect 4: Newspaper */
.md-effect-4 .md-content {
	-webkit-transform: scale(0) rotate(720deg);
	transform: scale(0) rotate(720deg);
	opacity: 0;
}

.md-show.md-effect-4 ~ .md-overlay,
.md-effect-4 .md-content {
	transition: all 0.5s;
}

.md-show.md-effect-4 .md-content {
	-webkit-transform: scale(1) rotate(0deg);
	transform: scale(1) rotate(0deg);
	opacity: 1;
}

/* Effect 5: fall */
.md-effect-5.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-5 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(600px) rotateX(20deg); 
	transform: translateZ(600px) rotateX(20deg); 
	opacity: 0;
}

.md-show.md-effect-5 .md-content {
	transition: all 0.3s ease-in;
	-webkit-transform: translateZ(0px) rotateX(0deg);
	transform: translateZ(0px) rotateX(0deg); 
	opacity: 1;
}

/* Effect 6: side fall */
.md-effect-6.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-6 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
	transform: translate(30%) translateZ(600px) rotate(10deg); 
	opacity: 0;
}

.md-show.md-effect-6 .md-content {
	transition: all 0.3s ease-in;
	-webkit-transform: translate(0%) translateZ(0) rotate(0deg);
	transform: translate(0%) translateZ(0) rotate(0deg);
	opacity: 1;
}

/* Effect 7:  slide and stick to top */
.md-effect-7{
	top: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.md-effect-7 .md-content {
	-webkit-transform: translateY(-200%);
	transform: translateY(-200%);
	transition: all .3s;
	opacity: 0;
}

.md-show.md-effect-7 .md-content {
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
	border-radius: 0 0 3px 3px;
	opacity: 1;
}

/* Effect 8: 3D flip horizontal */
.md-effect-8.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-8 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	transition: all 0.3s;
	opacity: 0;
}

.md-show.md-effect-8 .md-content {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	opacity: 1;
}

/* Effect 9: 3D flip vertical */
.md-effect-9.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-9 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateX(-70deg);
	transform: rotateX(-70deg);
	transition: all 0.3s;
	opacity: 0;
}

.md-show.md-effect-9 .md-content {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	opacity: 1;
}

/* Effect 10: 3D sign */
.md-effect-10.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-10 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateX(-60deg);
	transform: rotateX(-60deg);
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-10 .md-content {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	opacity: 1;
}

/* Effect 11: Super scaled */
.md-effect-11 .md-content {
	-webkit-transform: scale(2);
	transform: scale(2);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-11 .md-content {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Effect 12:  Just me */
.md-effect-12 .md-content {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-12 ~ .md-overlay {
	background: #e74c3c;
} 

.md-effect-12 .md-content h3,
.md-effect-12 .md-content {
	background: transparent;
}

.md-show.md-effect-12 .md-content {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Effect 13: 3D slit */
.md-effect-13.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-13 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(-3000px) rotateY(90deg);
	transform: translateZ(-3000px) rotateY(90deg);
	opacity: 0;
}

.md-show.md-effect-13 .md-content {
	-webkit-animation: slit .7s forwards ease-out;
	animation: slit .7s forwards ease-out;
}

@-webkit-keyframes slit {
	50% { -webkit-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -webkit-animation-timing-function: ease-out;}
	100% { -webkit-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@keyframes slit {
	50% { -webkit-transform: translateZ(-250px) rotateY(89deg); transform: translateZ(-250px) rotateY(89deg); opacity: 1; -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in;}
	100% { -webkit-transform: translateZ(0) rotateY(0deg); transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

/* Effect 14:  3D Rotate from bottom */
.md-effect-14.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-14 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateY(100%) rotateX(90deg);
	transform: translateY(100%) rotateX(90deg);
	-webkit-transform-origin: 0 100%;
	transform-origin: 0 100%;
	opacity: 0;
	transition: all 0.3s ease-out;
}

.md-show.md-effect-14 .md-content {
	-webkit-transform: translateY(0%) rotateX(0deg);
	transform: translateY(0%) rotateX(0deg);
	opacity: 1;
}

/* Effect 15:  3D Rotate in from left */
.md-effect-15.md-modal {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}

.md-effect-15 .md-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	-webkit-transform-origin: 0 100%;
	transform-origin: 0 100%;
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-15 .md-content {
	-webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
	transform: translateZ(0px) translateX(0%) rotateY(0deg);
	opacity: 1;
}

/* Effect 16:  Blur */
.md-show.md-effect-16 ~ .md-overlay {
	background: rgba(180,46,32,0.5);
}

.md-show.md-effect-16 ~ .container {
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	filter: blur(3px);
}

.md-effect-16 .md-content {
	-webkit-transform: translateY(-5%);
	transform: translateY(-5%);
	opacity: 0;
}

.md-show.md-effect-16 ~ .container,
.md-effect-16 .md-content {
	transition: all 0.3s;
}

.md-show.md-effect-16 .md-content {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

/* Effect 17:  Slide in from bottom with perspective on container */
.md-show.md-effect-17 ~ .container {
	height: 100%;
	overflow: hidden;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}	

.md-show.md-effect-17 ~ .container,
.md-show.md-effect-17 ~ .md-overlay  {
	-webkit-transform: rotateX(-2deg);
	transform: rotateX(-2deg);
	-webkit-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.md-effect-17 .md-content {
	opacity: 0;
	-webkit-transform: translateY(200%);
	transform: translateY(200%);
}

.md-show.md-effect-17 .md-content {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	transition: all 0.3s 0.2s;
}

/* Effect 18:  Slide from right with perspective on container */
.md-show.md-effect-18 ~ .container {
	height: 100%;
	overflow: hidden;
}

.md-show.md-effect-18 ~ .md-overlay {
	background: rgba(143,27,15,0.8);
	transition: all 0.5s;
}

.md-show.md-effect-18 ~ .container,
.md-show.md-effect-18 ~ .md-overlay {
	-webkit-transform-style: preserve-3d;
	-webkit-transform-origin: 0% 50%;
	-webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
	-moz-transform-style: preserve-3d;
	-moz-transform-origin: 0% 50%;
	-moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
	transform-style: preserve-3d;
	transform-origin: 0% 50%;
	animation: rotateRightSideFirst 0.5s forwards ease-in;
}

@-webkit-keyframes rotateRightSideFirst {
	50% { -webkit-transform: translateZ(-50px) rotateY(5deg); -webkit-animation-timing-function: ease-out; }
	100% { -webkit-transform: translateZ(-200px); }
}

@keyframes rotateRightSideFirst {
	50% { -webkit-transform: translateZ(-50px) rotateY(5deg); transform: translateZ(-50px) rotateY(5deg); -webkit-animation-timing-function: ease-out; animation-timing-function: ease-out; }
	100% { -webkit-transform: translateZ(-200px); transform: translateZ(-200px); }
}

.md-effect-18 .md-content {
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
	opacity: 0;
}

.md-show.md-effect-18 .md-content {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	transition: all 0.5s 0.1s;
}

/* Effect 19:  Slip in from the top with perspective on container */
.md-show.md-effect-19 ~ .container {
	height: 100%;
	overflow: hidden;
}

.md-show.md-effect-19 ~ .md-overlay {
	transition: all 0.5s;
}

.md-show.md-effect-19 ~ .container,
.md-show.md-effect-19 ~ .md-overlay {
	-webkit-transform-style: preserve-3d;
	-webkit-transform-origin: 50% 100%;
	-webkit-animation: OpenTop 0.5s forwards ease-in;
	-moz-transform-style: preserve-3d;
	-moz-transform-origin: 50% 100%;
	-moz-animation: OpenTop 0.5s forwards ease-in;
	transform-style: preserve-3d;
	transform-origin: 50% 100%;
	animation: OpenTop 0.5s forwards ease-in;
}

@-webkit-keyframes OpenTop {
	50% { 
		-webkit-transform: rotateX(10deg); 
		-webkit-animation-timing-function: ease-out; 
	}
}

@keyframes OpenTop {
	50% { 
		-webkit-transform: rotateX(10deg); 
		        transform: rotateX(10deg); 
		-webkit-animation-timing-function: ease-out; 
		        animation-timing-function: ease-out; 
	}
}

.md-effect-19 .md-content {
	-webkit-transform: translateY(-200%);
	transform: translateY(-200%);
	opacity: 0;
}

.md-show.md-effect-19 .md-content {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	transition: all 0.5s 0.1s;
}

@media screen and (max-width: 32em) {
	body { font-size: 75%; }
}
body{
    background-color: #000;
    font-family: 'Ubuntu', sans-serif;
    font-family: 'Acme', sans-serif;
    font-family: 'Righteous', cursive;
    background-color: #1abc89;
}
.max400{
    max-width: 400px;
}
.max800{
    max-width: 800px;
}
.App{
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
}
.footer{
    margin-top: 100px;
    color: #cfcf;
    text-align: center;
}
.startPlaying{
    margin-top: 20px;
    width: 250px;
    height: 60px;
}
.brand-item{
    position: relative;
}
.brand-item img{
    width: 100%;
    height: 300px;
    cursor: pointer;
}
.brand-item .cover{
    position: absolute;
    height: 300px;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
    top: 0;
}
.brand-item .title{
    text-align: left;
    position: absolute;
    bottom: 0;
    padding: 10px;
    font-weight: 900;
    font-size: 14px;
    width: 100%;
    background-color: rgba(27, 27, 27, 0.6);
    color: #fff;
}
.w400{
    width: 400px;
}

.center-div {
     margin: 0 auto;
     text-align: center;
}
input[type=tel], select{
    margin-top: 20px;
    width: 70%;
}
.text-center{
    text-align: center;
}
.aws-btn--disabled span{
    text-decoration: none !important;
}

.h70{
    height: 70px;
}
.mt-5{
    margin-top: -5px;
}
.mt-10{
    margin-top: -10px;
}
.mt20{
    margin-top: 20px;
}
.mt25{
    margin-top: 25px;
}
.mt50{
    margin-top: 50px;
}
.mt100{
    margin-top: 100px;
}
.mt-50{
    margin-top: -50px;
}
.mt-40{
    margin-top: -40px;
}
.mt-30{
    margin-top: -30px;
}
.mt5{
    margin-top: 5px;
}
.mt10{
    margin-top: 10px;
}
.mr6{
    margin-right: 6px;
}
.mr5{
    margin-right: 5px;
}
.l-s-1{
    letter-spacing: 1px;
}

button.aws-btn--disabled{
    cursor: auto;
}
.text10{
    font-size: 20px;
    font-weight: 500;
}
.full-width{
    width: 100%;
}
.white{
    color: #fff;
}

.red{
    color: #c80000;
}

.m-20{
    margin: 20px;
}

.mt40{
    margin-top: 40px;
}

.text30{
    font-size: 30px;
    font-weight: 800;
}
.text20{
    font-size: 30px;
    font-weight: 500;
}
.text22{
    font-size: 22px;
}
.text16{
    font-size: 18px;
}

.text14{
    font-size: 14px;
}
.iconImg{
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 3px;
}
.text15{
    font-size: 15px;
}
*:not(.row), ::after, ::before{
    box-sizing: inherit;
}
.logo{
    height: 40px;
    margin-bottom: 20px;
}

.logo2{
    height: 50px;
    margin-bottom: 20px;
}

.logo-sm{
    height: 50px;
    margin-bottom: 20px;
}

.animrotate5 {
    -webkit-animation:rotate5 1s linear infinite;
    animation:rotate5 1s linear infinite;
}

.animrotate-5 {
    -webkit-animation:rotate-5 1s linear infinite;
    animation:rotate-5 1s linear infinite;
}
.aboveConfetti{
    position: relative;
    z-index: 9;
}
@-webkit-keyframes rotate5 { 
    50% { -webkit-transform: rotate(10deg); }
    100% { -webkit-transform: rotate(0deg); } 
}
@keyframes rotate5 { 
    50% { -webkit-transform: rotate(10deg); transform:rotate(10deg); } 
    100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); } 
}
@-webkit-keyframes rotate-5 { 
    50% { -webkit-transform: rotate(-5deg); }
    100% { -webkit-transform: rotate(0deg); } 
}
@keyframes rotate-5 { 
    50% { -webkit-transform: rotate(-5deg); transform:rotate(-5deg); } 
    100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); } 
}

.rotate-5 {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
}
.rotate5 {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
}

.circleBase {
    border-radius: 50%;
}

.type1 {
    width: 50px;
    height: 50px;
    background: #fff;
    /* padding: 8px; */
}
.type3 {
    width: 30px;
    height: 30px;
    background: #fff;
    padding: 8px;
}
.hide{
    opacity: 0;
}
.type2 {
    width: 100px;
    height: 100px;
    background: #fff;
    padding: 8px;
}
.h20{
height: 10px;
overflow: hidden !important;
}
.blink1{
    -webkit-animation:eyeblink 0.2s ease 4s;
    animation:eyeblink 0.2s ease 4s;
}
.blink2{
    -webkit-animation:eyeblink 0.25s ease 4s 2;
    animation:eyeblink 0.25s ease 4s 2;
}
.angrybrowright{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 20% 40%;
            transform-origin: 20% 40%;
    border: 2px solid #1abc89;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    float: left;
    width: 30px;
    margin-left: 25px;
}

.angrybrowleft{
    float: right !important;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 20% 40%;
            transform-origin: 20% 40%;
    border: 2px solid #1abc89;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    float: left;
    width: 30px;
    margin-right: 8px
}
.eye{
    background-color:#1abc89;
    height: 15px;
    width: 20px;
    border-radius: 100%;
}
.eye2{
    background-color:#1abc89;
    height: 20px;
    width: 13px;
    border-radius: 100%;
}
.mouth2{
    border: 2px solid #1abc89;
    display: inline-block;
    min-width: 35px;
    min-height: 4em;
    padding: 0.3em;
    border-radius: 48%;
    border-left-color: transparent;
    border-right-color: transparent;
}
.mouth{
    border: 2px solid #1abc89;
    display: inline-block;
    min-width: 35px;
    min-height: 4em;
    padding: 0.3em;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
}
.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    
    border-bottom: 5px solid #1abc89;
  }
@-webkit-keyframes eyeblink { 
    0%   { height:   20px; }
  100% { height: 5px; }

  0%   { margin-top:   0px; }
  100% { margin-top: 3px; }
}
@keyframes eyeblink { 
    0%   { height:   20px; }
  100% { height: 5px; }

  0%   { margin-top:   0px; }
  100% { margin-top: 3px; }
}

.teardrop1{
    -webkit-animation:teardrop 1s ease 2s;
    animation:teardrop 1s ease 2s;
}
.teardrop2{
    -webkit-animation:teardrop 1s ease 1s;
    animation:teardrop 1s ease 1s;
}
.teardrop3{
    -webkit-animation:teardrop 1s ease 3s;
    animation:teardrop 1s ease 3s;
}
@-webkit-keyframes teardrop { 
    0%{opacity: 1;}
    50%{opacity: 0.8;}
    75%{opacity: 0.5;}
    100%{opacity: 0;}

  0%   { margin-top:   0px; }
  100% { margin-top: 70px; }
}
@keyframes teardrop { 
    0%{opacity: 1;}
    50%{opacity: 0.8;}
    75%{opacity: 0.5;}
    100%{opacity: 0;}
  
  0%   { margin-top:   0px; }
  100% { margin-top: 70px; }
}

.type1 span{
    font-size: 30px;
    line-height: 50px;
    color: #1abc89;
}

.cur-hand{
    cursor: pointer;
}

.text200{
    font-size: 200px;
}
div.inline{
    display: inline-block;
}

div.table{
    display: table;
    width: auto;
}

.ml10{
    margin-left: 10px !important;
}
.ml-50{
    margin-left: -50px !important;
}
.ml20{
    margin-left: 20px !important;
}
.ml30{
    margin-left: 30px !important;
}
.ml35{
    margin-left: 35px !important;
}
.processingDiv{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 860px;
    z-index: 999999;
    overflow: hidden;
    text-align: center;
    background-color: #1abc89;
    display: table;
}
.processingDiv #content{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}
.processingDiv #content span{
    color: #fff;
    font-size: 40px;
}

/*.option .aws-btn__content:not(.no-hover):hover{
    background-color: #1abc89 !important;
    color: #fff;
}*/

.text-left{
    text-align: left;
}
.mt0{
    margin-top: 0px !important;
}
.mb0{
    margin-bottom: 0px !important;
}

.app-name-5{
    font-size: 180px;
    font-weight: 400;
    font-style: italic;
    color: #e3fdd6;
    margin-top: -70px;
    margin-left: -65px;
    position: absolute;
    z-index: -1;
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}

.middle-div{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;   
}

.select-network{
    padding: 20px;
    background-color: rgba(0,0,0,0.6);
    margin-right: 10px;
    color: #fff;
}

.clear-fix{
    overflow: auto;
}

.md-overlay{
    background: rgba(227, 253, 214, 1) !important;
    /*background: rgba(26, 188, 137, 0.9) !important;*/
}

.md-content{
    background: #1abc89;
}
input[type=tel]{
    width: 100% !important;
}
.select-network.selected{
    background-color: #00af00;
}
.inline-btn{
    display: inline !important;
    display: initial !important;
}
.pr25{
    padding-right: 25px;
}
.pl25{
    padding-left: 27px;
}
.mt25{
    margin-top: 25px !important;
}
.textInverse{
    font-size: 22px;
    color: #21635e;
}
.smallImg{
    width: 175px;
    margin-bottom: 10px;
}

.smallImg2{
    width: 235px;
    margin-bottom: 10px;
}

.fadeinanime{
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
}
@-webkit-keyframes fadeIn {
    0% {display: none;opacity: 0;}
    1% {display: block;opacity: 0;}
    50% {display: block;opacity: 0.5;}
    100% {display: block;opacity: 1;}
}
@keyframes fadeIn {
    0% {display: none; opacity: 0;}
    1% {display: block; opacity: 0;}
    50% {display: block;opacity: 0.5;}
    100% {display: block; opacity: 1;}
}

.fadeoutanime{
    -webkit-animation: fadeOut 0.5s ease-out;
    animation: fadeOut 0.5s ease-out;
}
@-webkit-keyframes fadeOut {
    0% {display: block;opacity: 1;}
    1% {display: block;opacity: 1;}
    100% {display: block;opacity: 0;}
}
@keyframes fadeOut {
    0% {display: block;opacity: 1;}
    1% {display: block;opacity: 1;}
    100% {display: block;opacity: 0;}
}

#img-question{
    max-height: 250px;
    margin-top: 10px;
}

.hide{
    display: none;
}

.fade{
    opacity: 0;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 50px;
  }
  .lds-spinner div {
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  @-webkit-keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

.limitNoticeContainer .item{
    padding: 12px;
    border-radius: 20px;
    background-color: #e3fdd6;
    color: #000;
}

.aws-btn.red .aws-btn__content span{
    color: #8f3030;   
}

.aws-btn.green .aws-btn__content span{
    color: #018001;   
}

.aws-btn.purple .aws-btn__content span{
    color: #862a86;   
}

.aws-btn.orange .aws-btn__content span{
    color: #ff5437;   
}

.white-bg{
    background-color: #fff;
}
